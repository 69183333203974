<template>
	<screen size="small" class="login-view">
		<template slot="body">
			<div class="login-header">
				<div class="roundup-logo"></div>
			</div>
		</template>
	</screen>
</template>

<script>
import { mapActions } from 'vuex'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'
import UiLoader from '../../ui/Loader'

export default {
	name: 'login_with_token_view',
	components: { UiButton, UiInput, UiLabel, Screen, UiLoader },
	props: ['token'],
	data() {
		return {
			messageLoading: true,
			isLoading: false
		}
	},
	methods: {
		...mapActions(['loginWithToken'])
	},
	mounted() {
		this.isLoading = true
		this.loginWithToken(this.token)
			.then(data => {
				this.isLoading = false
				this.$router.push({ name: 'steps' })
			})
			.catch(({ message }) => {
				this.isLoading = false
				this.$router.push({ name: 'welcome' })
				this.$swal('Error', message)
			})
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.screen {
	&.login-view {
		padding-top: 60px;
		text-align: left;
		.login-header {
			p {
				margin: 0px;
			}
		}
		.roundup-logo {
			background: url('../../../assets/roundup_logo.svg');
			background-size: 137px 14px;
			background-repeat: no-repeat;
			background-position: center;
			width: 100%;
			height: 14px;
			margin: 0px 0px 20px;
		}
		.forgot-password {
			font-size: 13px;
		}
	}
}
</style>
